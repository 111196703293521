import React from "react"

const Photo = ({ align = "left", src }) => {
  const className =
    align === "right"
      ? "sm:w-1/2 sm:rounded-l-2xl"
      : "sm:w-1/2 sm:rounded-r-2xl"

  return (
    <div
      className={className}
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: 400,
      }}
    />
  )
}

export default Photo
