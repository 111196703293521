import React from "react"
import { SiKubernetes, SiGit } from "react-icons/si"
import { IoInfinite } from "react-icons/io5"
import { AiOutlineCloudUpload } from "react-icons/ai"
import { BsShieldCheck } from "react-icons/bs"
import { SlMagnifier } from "react-icons/sl"
import { useTranslation } from "react-i18next"
import { H2 } from "./Styling"

const Services = () => {
  const [t] = useTranslation("global")
  const features = [
    {
      name: "Kubernetes",
      description: t("services.1"),
      icon: <SiKubernetes className="text-white" size={20} />,
    },
    {
      name: "Infrastructure as Code",
      description: t("services.2"),
      icon: <SiGit className="text-white" size={20} />,
    },
    {
      name: "Observability",
      description: (
        <>
          {t("services.3-1")}
          <a
            className="text-primary underline"
            rel="noreferrer"
            target="_blank"
            href="https://grafana.com/"
          >
            LGTM-stack
          </a>
          {t("services.3-2")}
        </>
      ),
      icon: <SlMagnifier className="text-white" size={20} />,
    },
    {
      name: "GitOps",
      description: (
        <>
          {t("services.4-1")}
          <a
            className="text-primary underline"
            rel="noreferrer"
            target="_blank"
            href="https://about.gitlab.com/topics/gitops/"
          >
            {t("services.4-2")}
          </a>
          {t("services.4-3")}
        </>
      ),
      icon: <IoInfinite className="text-white" size={20} />,
    },
    {
      name: "Security",
      description: t("services.5"),
      icon: <BsShieldCheck className="text-white" size={20} />,
    },
    {
      name: "Cloud",
      description: t("services.6"),
      icon: <AiOutlineCloudUpload className="text-white" size={20} />,
    },
  ]
  return (
    <div
      id="services"
      className="w-full bg-gray-100 pt-16 sm:pt-24 pb-24 sm:pb-32 px-4"
    >
      <div className="max-w-[1240px] mx-auto">
        <div className="mx-auto max-w-2xl lg:text-center mb-10">
          <H2 className="text-center">{t("services.header")}</H2>
        </div>

        <div className="mx-auto max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-600">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primary">
                    {feature.icon}
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Services
