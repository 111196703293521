import React from "react"
import { useTranslation } from "react-i18next"
import { H2 } from "./Styling"
import Photo from "./Photo"
import PhotoContent from "./PhotoContent"

const About = () => {
  const [t] = useTranslation("global")

  return (
    <div id="about" className="bg-gray-100 pt-16 sm:pt-24 pb-24 sm:pb-32">
      <PhotoContent>
        <Photo align="left" src="/images/about.jpeg" />

        <div className="sm:w-1/2 text-gray-600 pl-4 pr-4 sm:pr-16">
          <H2>{t("about.header")}</H2>

          <p className="mt-4">
            {t("about.1-1")}
            <a
              className="text-primary"
              href="https://www.linkedin.com/in/pim-polderman-93476b282"
              target="_blank"
              rel="noreferrer"
            >
              Pim{", "}
            </a>
            <a
              className="text-primary"
              href="https://www.linkedin.com/in/jsteterissa"
              target="_blank"
              rel="noreferrer"
            >
              Jordi{" "}
            </a>
            {t("about.1-2")}
            <a
              className="text-primary"
              href="https://www.linkedin.com/in/richard-van-der-veen"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Richard,{" "}
            </a>
            {t("about.1-3")}
          </p>
          <p className="mt-4">{t("about.2")}</p>
          <p className="mt-4">
            {t("about.3-1")}
            <a
              className="text-primary"
              href="https://www.vannellefabriekrotterdam.com/en/home/"
              target="_blank"
              rel="noreferrer"
            >
              Van Nelle Fabriek
            </a>
            {t("about.3-2")}
          </p>
        </div>
      </PhotoContent>
    </div>
  )
}

export default About
