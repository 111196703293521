import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import global_en from "./translations/en/global.json"
import global_nl from "./translations/nl/global.json"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import { RouterProvider } from "react-router-dom"
import { router } from "./router"

i18next.init({
  interpolation: { escapeValue: true },
  lng: "en",
  resources: {
    nl: {
      global: global_nl,
    },
    en: {
      global: global_en,
    },
  },
})

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </React.StrictMode>,
)
