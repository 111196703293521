import React from "react"

const PhotoContent = ({ children }) => {
  return (
    <div className="flex flex-col-reverse sm:flex-row justify-center max-w-[2024px] mx-auto gap-10">
      {children}
    </div>
  )
}

export default PhotoContent
