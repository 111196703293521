import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

export const useLanguagePicker = (defaultLanguage = "en") => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const [selectedLanguage, setSelectedLanguage] = useState(
    currentLanguage || defaultLanguage,
  )

  const setLanguage = (e) => {
    let language = selectedLanguage === "en" ? "nl" : "en"
    setSelectedLanguage(language)
  }

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
  }, [selectedLanguage, i18n])

  return [selectedLanguage, setLanguage]
}
