import React from "react"
import { useTranslation } from "react-i18next"
import { H2 } from "./Styling"

const Mission = () => {
  const [t] = useTranslation("global")

  return (
    <div
      id="mission"
      className="w-full bg-gray-100 text-gray-600 pt-16 sm:pt-24 pb-24 sm:pb-32 px-4"
    >
      <div className="max-w-[1240px] mx-auto">
        <div className="mx-auto max-w-2xl">
          <H2 className="sm:text-center text-gray-600">{t("mission.header")}</H2>
          <p className="sm:text-center">{t("mission.1")}</p>
        </div>

        <div className="mt-10 sm:mt-16">
          <div className="flex flex-col sm:flex-row justify-center">
            <div className="sm:w-1/3 px-4">
              <p className="text-xl font-bold tracking-tight mb-4">
                {t("mission.2")}
              </p>
              <p>{t("mission.3")}</p>
            </div>

            <div className="sm:w-1/3 px-4 pt-10 sm:pt-0">
              <p className="text-xl font-bold tracking-tight mb-4">
                {t("mission.4")}
              </p>
              <p>{t("mission.5")}</p>
              <p className=""></p>
            </div>

            <div className="sm:w-1/3 px-4 pt-10 sm:pt-0">
              <p className="text-xl font-bold tracking-tight mb-4">
                {t("mission.6")}
              </p>
              <p>{t("mission.7")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mission
