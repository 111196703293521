import React from "react"
import { useTranslation } from "react-i18next"
import utrecht from "../img/utrecht.svg"
import wonderbit from "../img/wonderbit.png"
import pleio from "../img/pleio.png"
import ritense from "../img/ritense.png"
import lbvd from "../img/lbvd.png"
import { H2 } from "./Styling"

const Customers = () => {
  const [t] = useTranslation("global")

  return (
    <div
      id="customers"
      className="w-full bg-white pt-16 sm:pt-24 pb-24 sm:pb-32"
    >
      <div className="bg-white">
        <div className="mx-auto max-w-2xl lg:text-center mb-16">
          <H2 className="text-center">{t("clients.header")}</H2>
        </div>
        <div className="mx-auto max-w-5xl">
          <div className="flex flex-col space-y-16 sm:space-y-0 sm:flex-row justify-around items-center flex-wrap gap-x-4 gap-y-2 md:gap-y-20 lg:gap-x-2 space-x-4">
            <a href="https://www.utrecht.nl/" target="_blank" rel="noreferrer">
              <img
                className="w-48 sm:w-80 object-contain"
                src={utrecht}
                alt="Gemeente Utrecht"
              />
            </a>
            <a href="https://pleio.nl/" target="_blank" rel="noreferrer">
              <img
                className="w-24 sm:w-32 object-contain"
                src={pleio}
                alt="Pleio"
              />
            </a>
            <a href="https://ritense.com/" target="_blank" rel="noreferrer">
              <img
                className="w-48 sm:w-80 object-contain"
                src={ritense}
                alt="Ritense"
              />
            </a>
            <a href="https://lbvd.nl/" target="_blank" rel="noreferrer">
              <img
                className="w-48 sm:w-80 object-contain"
                src={lbvd}
                alt="LBVD"
              />
            </a>
            <a
              href="https://www.wonderbit.nl/nl/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-80 object-contain"
                src={wonderbit}
                alt="Wonderbit"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customers
