import React from "react"
import { useTranslation } from "react-i18next"
import { H2, Paragraph } from "./Styling"
import Photo from "./Photo"
import PhotoContent from "./PhotoContent"

const Contact = () => {
  const [t] = useTranslation("global")

  return (
    <div className="bg-gray-100 pt-16 sm:pt-24 pb-24 sm:pb-32">
      <PhotoContent>
        <div className="sm:w-1/2 text-gray-600 pr-4 pl-4 sm:pl-16">
          <H2>{t("contact.header")}</H2>

          <Paragraph>{t("contact.introduction")}</Paragraph>

          <Paragraph>
            {t("contact.phone")}
            <a href="tel:+31103072229" className="hover:underline">
              +31 (0)10 3072229
            </a>
            .
          </Paragraph>

          <button
            className="hover:underline"
            onClick={() => {
              // eslint-disable-next-line no-undef
              Futy.activate("contactform", true)
            }}
          >
            <Paragraph>{t("contact.contactform")}</Paragraph>
          </button>
        </div>

        <Photo align="right" src="/images/contact.jpeg" />
      </PhotoContent>
    </div>
  )
}

export default Contact
