import { H2 } from "./headings"

export const GenericPageWrapper = ({ title, children }) => (
  <div className="w-full bg-gray-100 pt-16 sm:pt-24 pb-8 sm:pb-16 px-4">
    <div className="max-w-[1240px] mx-auto">
      <div className="mx-auto max-w-2xl lg:text-center">
        <H2 className="text-center leading-tight mb-4">{title}</H2>
      </div>

      <div className="mx-auto max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        {children}
      </div>
    </div>
  </div>
)

export const BluePageWrapper = ({ children }) => (
  <div className="w-full bg-primary_faded4 py-12 sm:py-20 px-4">
    <div className="max-w-[1240px] mx-auto">
      <div className="mx-auto max-w-2xl lg:max-w-4xl">{children}</div>
    </div>
  </div>
)

export const Paragraph = ({ className, children }) => (
  <div className={`leading-relaxed mb-6 [&:last-child]:mb-0 ${className}`}>
    {children}
  </div>
)

export const UL = ({ className, children }) => (
  <ul className={`[&>li]:mb-2 mb-8 ${className}`}>{children}</ul>
)
