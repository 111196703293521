import React from "react"
import { Outlet } from "react-router-dom"
import Hero from "./components/Hero"
import Footer from "./components/Footer"

function Layout({ children }) {
  return (
    <div>
      <Hero />
      <Outlet />
      <Footer />
    </div>
  )
}

export default Layout
