import React from "react"
import { ReactTyped as Typed } from "react-typed"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"

import Navbar from "./Navbar"
import logo from "../img/logo_met_tekst_wit.svg"

const Hero = () => {
  const { t } = useTranslation("global")
  const location = useLocation()

  const isHomepage = location.pathname === "/"

  return (
    <div className="bg-gray-100 text-white bg-[url('img/hero.jpg')] bg-cover relative">
      <div className="bg-primary_faded">
        <div className="flex flex-row max-w-7xl m-auto">
          <div className={`${isHomepage ? "sm:min-h-screen" : ""} w-full`}>
            <div className="px-4 p-10 sm:px-10">
              <div className="flex flex-nowrap justify-between">
                <h1>
                  <Link to="/">
                    <img
                      className={isHomepage ? "h-20 mb-8" : "h-20"}
                      src={logo}
                      alt="Formatic - Specialists in Cloud Native Infrastructure"
                    />
                  </Link>
                </h1>
                <Navbar />
              </div>
              {isHomepage && (
                <div className="border-primary border-2 rounded-xl bg-primary_faded2 p-10 inline-block max-w-[calc(100%-16px)]">
                  <h1>
                    <div className="sm:text-6xl text-4xl font-bold">
                      {t("hero.header-1")}
                    </div>
                    <div className="sm:text-6xl text-4xl font-bold">
                      {t("hero.header-2")}
                    </div>
                  </h1>
                  <div className="flex flex-col justify-center my-10">
                    <p className="sm:text-4xl text-4xl font-bold">
                      {t("hero.1")}
                      <Typed
                        className="hidden sm:block text-4xl font-bold text-cyan-400"
                        strings={[
                          "Kubernetes",
                          "Observability",
                          "Security",
                          "GitOps",
                        ]}
                        typeSpeed={80}
                        backSpeed={80}
                        loop
                      />
                    </p>
                    <p>
                      <Typed
                        className="block sm:hidden text-4xl font-bold text-cyan-400"
                        strings={[
                          "Kubernetes",
                          "Observability",
                          "Security",
                          "GitOps",
                        ]}
                        typeSpeed={80}
                        backSpeed={80}
                        loop
                      />
                    </p>
                  </div>
                  <p className="sm:text-xl max-w-xl">{t("hero.2")}</p>
                  <Link
                    type="button"
                    className="bg-cyan_faded hover:bg-blue-700 text-white font-bold py-2 px-4 mt-10 rounded duration-200"
                    to={"/contact"}
                  >
                    {t("hero.3")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
