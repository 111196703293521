import React from "react"
import { createBrowserRouter } from "react-router-dom"

import App from "./App"
import Layout from "./Layout"
import ErrorPage from "./ErrorPage"
import About from "./components/About"
import Contact from "./components/Contact"
import Mission from "./components/Mission"
import Careers from "./components/Careers"
import Services from "./components/Services"
import Customers from "./components/Customers"
import CloudNativeEngineer from "./components/CloudNativeEngineer"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/*",
    element: <Layout />,
    children: [
      {
        path: "services",
        element: <Services/>,
      },
      {
        path: "mission",
        element: <Mission />,
      },
      {
        path: "about",
        element: <><About/><Customers/></>,
      },
      //{
      //  path: "careers",
      //  element: <Careers />,
      //},
      //{
      //  path: "careers/:jobTitle",
      //  // TODO: if we have more than 1 job title, we can create a separate component for it
      //  element: <CloudNativeEngineer />,
      //},
      {
        path: "contact",
        element: <Contact />,
      },
      {
        // Catch-all route
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
])
