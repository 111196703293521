export const LinkButton = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className="max-w-[50%] block bg-primary text-white text-center rounded-lg font-bold p-4 hover:bg-blue-800 ease-in duration-300"
  >
    {children}
  </a>
)
