import React, { useState } from "react"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import logo from "../img/logo_met_tekst_wit.svg"
import LanguagePicker from "./LanguagePicker"
import { useLanguagePicker } from "../hooks/useLanguagePicker"

const MobileMenuListItem = ({ onClick, to, children }) => {
  return (
    <li className="p-4 border-b border-gray-500">
      <Link onClick={onClick} className="p-4" to={to}>
        {children}
      </Link>
    </li>
  )
}

const Navbar = () => {
  const [navVisible, setNav] = useState(false)
  const handleNav = () => setNav(!navVisible)

  const [selectedLanguage, setLanguage] = useLanguagePicker()
  const { t } = useTranslation("global")

  return (
    <>
      <div className="py-6 h-30 text-white font-bold text-xl">
        <div className="flex justify-end">
          <ul className="hidden md:flex justify-between ml-6 md:ml-12 uppercase flex-wrap gap-x-8 gap-y-2 lg:gap-x-20 lg:mr-10">
            <Link to={"/services"}>{t("navigation.1")}</Link>
            <Link to={"/about"}>{t("navigation.3")}</Link>
            <Link to={"/mission"}>{t("navigation.2")}</Link>
            <Link to={"/contact"}>{t("navigation.5")}</Link>
            <LanguagePicker />
          </ul>
        </div>

        {!navVisible && (
          <div
            onClick={handleNav}
            className="block md:hidden absolute right-10 z-20"
          >
            <AiOutlineMenu size={30} />
          </div>
        )}

        <div
          className={
            navVisible
              ? "fixed left-0 top-0 w-full md:w-[60%] border-r border-r-gray-500 h-screen bg-gray-800 ease-in-out duration-500 z-[10000000000]"
              : "fixed left-[-100%]"
          }
        >
          {navVisible && (
            <div
              onClick={handleNav}
              className="block md:hidden absolute top-10 right-10 z-20"
            >
              <AiOutlineClose size={30} />
            </div>
          )}

          <img className="ml-4 mt-4 w-40" src={logo} alt="Logo Formatic" />

          <ul className="uppercase p-4">
            <MobileMenuListItem onClick={handleNav} to={"/"}>
              {t("navigation.1")}
            </MobileMenuListItem>
            <MobileMenuListItem onClick={handleNav} to={"/mission"}>
              {t("navigation.2")}
            </MobileMenuListItem>
            <MobileMenuListItem onClick={handleNav} to={"/about"}>
              {t("navigation.3")}
            </MobileMenuListItem>
            <MobileMenuListItem onClick={handleNav} to={"/contact"}>
              {t("navigation.5")}
            </MobileMenuListItem>
            <li className="p-4">
              <button className="uppercase px-4" onClick={setLanguage}>
                {selectedLanguage === "en" ? "Nederlands" : "English"}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Navbar
