export const H2 = ({ className, children }) => (
  <h2
    className={`text-gray-600 text-5xl font-bold tracking-tight mb-10 ${className}`}
  >
    {children}
  </h2>
)

export const H3 = ({ className, children }) => (
  <h3
    className={`${className} text-gray-600 text-3xl font-bold tracking-tight mb-4`}
  >
    {children}
  </h3>
)
