import React from "react"
import Hero from "./components/Hero"
import Footer from "./components/Footer"

function App() {
  return (
    <div>
      <Hero />
      <Footer />
    </div>
  )
}

export default App
