import { Link } from "react-router-dom"

export default function ErrorPage() {
  return (
    <div id="error-page">
      <div className="max-w-7xl m-auto p-10">
        <h2>Page cannot be found</h2>
        <p>
          Go back to the <Link to="/">homepage</Link>.
        </p>
      </div>
    </div>
  )
}
