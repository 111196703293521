import "/node_modules/flag-icons/css/flag-icons.min.css"

import { useLanguagePicker } from "../hooks/useLanguagePicker"

export default function LanguagePicker() {
  const [selectedLanguage, setLanguage] = useLanguagePicker()

  return (
    <button
      type="button"
      className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow shadow-primary ring-1 ring-inset ring-gray-300 hover:bg-gray-50 fixed right-5 top-5"
      onClick={setLanguage}
    >
      {selectedLanguage === "en" ? "Nederlands" : "English"}
    </button>
  )
}
