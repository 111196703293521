import React from "react"
import privacyStatement from "../assets/Privacy_Verklaring_Formatic.pdf"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const Footer = () => {
  const { t, i18n } = useTranslation("global")
  const { language } = i18n

  const navigation = {
    company: [
      { name: t("footer.nav1"), href: "/" },
      { name: t("footer.nav3"), href: "/mission" },
      { name: t("footer.nav4"), href: "/about" },
      //{ name: t("footer.nav5"), href: "/careers" },
      { name: t("footer.nav6"), href: "/contact" },
    ],
    legal: [],
  }

  const certificateUrl = {
    nl: "docs/ISO-IEC_27001-NLD-C632028-0-20240521.pdf",
    en: "docs/ISO-IEC_27001-ENG-C632028-0-20240521.pdf",
  }

  return (
    <footer
      className="bg-primary text-white border border-slate-400"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 px-10 my-16">
        <div className="order-last md:order-first">
          <div className="bg-white inline-block p-3 rounded-lg">
            <a href={certificateUrl[language]} rel="noreferrer" target="_blank">
              <img
                src="/images/dnv-iso-27001.png"
                alt="DNV - Information Security Management System ISO/IEC 27001"
                className="w-[168px]"
              />
            </a>
          </div>
        </div>
        <div>
          <div className="space-y-2 sm:space-y-4">
            <p className="text-m leading-6 font-semibold">Formatic B.V.</p>
            <p className="text-sm leading-6">
              <a
                className="flex gap-2 items-center text-white hover:text-gray-800 relative -left-8 duration-300"
                href="https://maps.app.goo.gl/i3Ekx9sSkuMg8P1h7"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                  <circle cx="12" cy="10" r="3"></circle>
                </svg>
                Van Nelleweg 1
                <br />
                3044BC Rotterdam
              </a>
            </p>
            <p className="text-sm leading-6">
              <a href="tel:+31103072229" className="hover:underline">
                +31 (0)10 3072229
              </a>
            </p>
            <p className="text-sm leading-6">
              <a href="mailto:hello@formatic.nl" className="hover:underline">
                hello@formatic.nl
              </a>
            </p>
            <p className="text-sm leading-6">KvK: 90126262</p>
          </div>
        </div>

        <div>
          <h3 className="text-m font-semibold leading-6 text-white">Company</h3>
          <ul className="mt-2 sm:mt-6 space-y-2 sm:space-y-4">
            {navigation.company.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className="text-sm leading-6 hover:text-gray-800"
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="text-m font-semibold leading-6 text-white">Legal</h3>
          <ul className="mt-2 sm:mt-6 space-y-2 sm:space-y-4">
            <li>
              <a
                href="https://www.formatic.nl/algemene_voorwaarden.pdf"
                target="_blank"
                rel="noreferrer"
                className="text-sm leading-6 hover:text-gray-800"
              >
                {t("footer.conditions1")}
              </a>
            </li>
            <li>
              <a
                href="https://www.formatic.nl/voorwaarden_strippenkaart.pdf"
                target="_blank"
                rel="noreferrer"
                className="text-sm leading-6 hover:text-gray-800"
              >
                {t("footer.conditions2")}
              </a>
            </li>
            <li>
              <a
                href={privacyStatement}
                className="text-sm leading-6 hover:text-gray-800"
              >
                {t("footer.conditions3")}
              </a>
            </li>
            <li>
              <a
                href="https://www.formatic.nl/policy.html"
                target="_blank"
                rel="noreferrer"
                className="text-sm leading-6 hover:text-gray-800"
              >
                {t("footer.conditions4")}
              </a>
            </li>
            {navigation.legal.map((item) => (
              <li key={item.name}>
                <a
                  href={item.href}
                  target="_blank"
                  className="text-sm leading-6 hover:text-gray-800"
                  rel="noreferrer"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="border-t border-white/10 text-center">
        <p className="text-xs leading-5 py-4 text-gray-200">
          &copy; {new Date().getFullYear()} Formatic B.V. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
